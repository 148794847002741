<template>
    <div class="intro">
        <p>
            Ce site est en construction.
            <br/>
            Vous pouvez consulter la version en préproduction ainsi que la 
            version en développement en cliquant sur les liens ci-dessous.
        </p>
    </div>
</template>

<script>
export default {
    name: 'Intro'
}
</script>

<style scoped>
.intro {
        width: 100vw;
    max-width: 512px;
    min-width: 320px;

    padding: 0 10px 0 5px;

    text-align: justify;
}
</style>